import(/* webpackMode: "eager" */ "/root/blwall-com/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/root/blwall-com/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager" */ "/root/blwall-com/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/root/blwall-com/node_modules/next/dist/shared/lib/lazy-dynamic/dynamic-bailout-to-csr.js");
;
import(/* webpackMode: "eager" */ "/root/blwall-com/node_modules/next/dist/shared/lib/lazy-dynamic/preload-chunks.js");
;
import(/* webpackMode: "eager" */ "/root/blwall-com/screens/Main/About/About.css");
;
import(/* webpackMode: "eager", webpackExports: ["OurTeamClient"] */ "/root/blwall-com/screens/Main/OurTeam/OurTeamClient.tsx");
;
import(/* webpackMode: "eager" */ "/root/blwall-com/shared/images/black-wall-architecture-studio-main-page-mob.webp");
;
import(/* webpackMode: "eager" */ "/root/blwall-com/shared/images/black-wall-architecture-studio-main-page.webp");
;
import(/* webpackMode: "eager" */ "/root/blwall-com/shared/images/logo-dark.webp");
;
import(/* webpackMode: "eager" */ "/root/blwall-com/shared/images/logo-white.webp");
;
import(/* webpackMode: "eager" */ "/root/blwall-com/shared/images/telegram (12) 1.webp");
;
import(/* webpackMode: "eager" */ "/root/blwall-com/shared/images/whatsapp (7) 1.webp");
;
import(/* webpackMode: "eager" */ "/root/blwall-com/shared/lib/AnimationControls.tsx");
;
import(/* webpackMode: "eager" */ "/root/blwall-com/shared/scripts/CookieConsentComponent.tsx");
;
import(/* webpackMode: "eager" */ "/root/blwall-com/shared/skeletons/CarouselSkeleton.css");
;
import(/* webpackMode: "eager" */ "/root/blwall-com/shared/ui/ContactUs/page.module.css");
;
import(/* webpackMode: "eager" */ "/root/blwall-com/shared/ui/ContactUs/ContactUs.css");
;
import(/* webpackMode: "eager" */ "/root/blwall-com/shared/ui/Logo/Logo.css");
;
import(/* webpackMode: "eager", webpackExports: ["Navbar"] */ "/root/blwall-com/shared/ui/Navbar/Navbar.tsx");
;
import(/* webpackMode: "eager" */ "/root/blwall-com/shared/ui/TermsAndConditions/TermsAndConditions.tsx");
;
import(/* webpackMode: "eager" */ "/root/blwall-com/widgets/Header/HeaderCTABtn/HeaderCTABtn.tsx");
;
import(/* webpackMode: "eager" */ "/root/blwall-com/widgets/Header/HeaderLanguageSelector/HeaderLanguageSelector.tsx");
;
import(/* webpackMode: "eager" */ "/root/blwall-com/widgets/Hero/skeleton/PreloaderSkeleton.css");
;
import(/* webpackMode: "eager" */ "/root/blwall-com/widgets/Header/page.module.css");
;
import(/* webpackMode: "eager" */ "/root/blwall-com/widgets/Footer/Footer.css");
;
import(/* webpackMode: "eager" */ "/root/blwall-com/widgets/Awards/ui/Awards.css");
;
import(/* webpackMode: "eager" */ "/root/blwall-com/screens/Main/Press/Press.css");
;
import(/* webpackMode: "eager" */ "/root/blwall-com/screens/Main/News/News.css");
;
import(/* webpackMode: "eager" */ "/root/blwall-com/screens/Main/Main.css");
;
import(/* webpackMode: "eager" */ "/root/blwall-com/widgets/Hero/ui/HeroClient.tsx");
;
import(/* webpackMode: "eager" */ "/root/blwall-com/widgets/Instagram/ui/InstagramClient.tsx");
;
import(/* webpackMode: "eager" */ "/root/blwall-com/widgets/MainCarousel/ui/MainCarouselClient.tsx");
;
import(/* webpackMode: "eager" */ "/root/blwall-com/widgets/OrderForm/skeleton/OrderFormSkeleton.css");
;
import(/* webpackMode: "eager" */ "/root/blwall-com/widgets/OrderForm/ui/OrderFormClient.tsx");
